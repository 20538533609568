import React from "react";
import styles from './BattleResultsItem.module.scss'

interface BattleProps {
 index: number,
 genre: string,
 ended_at: string,
 winner: string,
 prize: number,
 players: number,
 cover: string,
 battle: number
}

const BattleResultsItem = (props: BattleProps) => {
 return(
  <div className={props.index % 2 === 0 ? styles.battleItem : styles.battleItem_even}>
   <div className={styles.cover_wrapp}>
  <img className={styles.cover} src={props.cover} alt="cover"/>
   </div>
   <div>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Tournament: {props.genre}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Battle: #{props.battle}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>End Date: {props.ended_at}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Winner: {props.winner}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Prize pool: {props.winner}</p>
    <p className={props.index % 2 === 0 ? styles.description : styles.description_even}>Winning players: {props.players}</p>
   </div>
  </div>
 )
}

export default BattleResultsItem;
