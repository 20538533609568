import { Col, Row } from 'antd';

import styled from './Terms.module.scss';
import MusicHeader from '../../components/MusicHeader';

const Terms = () => {

  return (
    <div className={styled.wrapper}>
          <MusicHeader title='Terms & Conditions' primaryColor='#0E664B' color='#fff'/>

      <Row className={styled.container}>
        <Col /* className={styled.subHeader} */ span={24}>
          <p className={styled.text}>
            Before Player's transaction can be completed, Player must read and agree to these terms and conditions, "Agreement". By applying for access and / or services from this website, Player is agreeing to these terms and conditions, and is agreeing to be legally bound by them. This Agreement is subject to change at any time. Changes are effective when posted on this site without notice to each Player.
          </p>
          <h2 className={styled.subHeader}>
            PREAMBLE
          </h2>
          <p className={styled.text}>
            Player's credit card will be billed immediately after purchase. <br />
            After purchase Player will receive an email notification with all payment details. The contract is closed between Player and Site as soon as the order is submitted.<br />
            All questions will be answered within six working days.<br />
            Any motion pictures, photographs, or other content found on the Site and any subscription to the Site is expressly prohibited for anyone under legal age in their respective country/jurisdiction, and as a minimum to anyone below 18 years of age.
          </p>

          <h2 className={styled.subHeader}>DEFINITIONS</h2>
          <p className={styled.text}>
            "Account" shall mean the Players or user of a valid username and password for the site.<br />
            "Site" shall mean the website Playporn.bet for which Player is purchasing game tokens in order to access the site and its materials and obtain the benefits of Account and services of site.<br />
            "Player" shall mean the user of the services of the site and holder of a valid username and password for the Site.<br />
            "Access Right," shall mean the combination of unique username and password that is used to access a site. An access right is a license to use a Site.
          </p>

          <h2 className={styled.subHeader}>DESCRIPTION OF SERVICES</h2>
          <p className={styled.text}>Playporn.bet will provide one access right to access the Site and its materials for which Player is purchasing game tokens.</p>

          <h2 className={styled.subHeader}>BILLING</h2>
          <p className={styled.text}>Billing is processed by one of our partners (Aureavia or others). Player will be informed at the time of purchasing game tokens what description will appear on Player’s credit card, bank statement, or phone bill for all applicable charges. If multiple venues are joined utilizing any payment method, Player’s statement will list each individual purchase comprising the transaction. Billing may include other information.</p>

          <h2 className={styled.subHeader}>ELECTRONIC RECEIPT</h2>
          <p className={styled.text}>Player will receive an email receipt to their email provided upon transaction. Requests must be made directly to Playporn.bet. To contact Playporn.bet refer to Customer Support links on the Site.</p>

          <h2 className={styled.subHeader}>CANCELLATION</h2>
          <p className={styled.text}>At any time, and without cause, player Account to the service may be terminated by either: Playporn.bet, the Site, or the Player upon notification of the other by electronic mail or by chat. Players are liable for charges incurred until the date of the termination.</p>

          <h2 className={styled.subHeader}>REFUNDS</h2>
          <p className={styled.text}>Refunds for purchases charges may be requested by contacting customer support. Refunds or credits will not be issued for used game tokens. Playporn.bet reserves the right to grant any refunds or credits at its discretion. The decision to refund a charge does not imply the obligation to issue additional future refunds. Should a refund be issued by Playporn.bet for any reason, it will be credited solely to the payment method used in the original transaction. Playporn.bet will not issue refunds by cash, check, or to another payment mechanism.</p>

          <h2 className={styled.subHeader}>CARDHOLDER DISPUTES/CHARGEBACKS</h2>
          <p className={styled.text}>We review all chargebacks carefully to ensure no party is incorrectly charged. We also reserve the right to deny future purchases to accounts with chargebacks that may lack merit depending on the circumstances. Claims of fraud are taken seriously and may result in the Site notifying Player’s issuer to protect Player and prevent future fraudulent charges to Player’s card.</p>

          <h2 className={styled.subHeader}>AUTHORIZATION OF USE</h2>
          <p className={styled.text}>Players to the Site are authorized to access the service or material located at this website through a single Access Right. This Access Right shall be granted for sole use to one Player. All Accounts are provided for personal use only and shall not be used for any commercial purposes or by any other third parties. Commercial use of either the Site or any material found within it is strictly prohibited unless authorized by the Site. No material within the Site may be transferred to any other person or entity, whether commercial or non-commercial. No material within the Site may be distributed, downloaded, or otherwise obtained through peer-to-peer networks or any other file sharing platforms. In addition, materials may not be modified, or altered. Materials may not be displayed publicly, or used for any rental, sale, or display. Materials extend to copyright, trademarks, or other proprietary notices. The Site reserve the right to terminate the Access Right at any time for any Player if the terms of this Agreement are breached. In the event that the terms are breached, Player will be required to immediately destroy any information or material printed, downloaded or otherwise copied from the Site.</p>

          <h2 className={styled.subHeader}>TRANSFER OF YOUR ACCESS RIGHT</h2>
          <p className={styled.text}>Access to the Site - the Player’s Access Right - is through a combination of a username and a password. Players may not release their Access Right to any person, and are required to keep their Access Right confidential. Playporn.bet will not release a Player’s password, to anyone other than the Player, except as may be required by law or court order. Unauthorized access to the Site - including sharing your Access Right with others - is a breach of this Agreement. Players acknowledge that the Site may track each Player's entry to the site to prevent unauthorized access. In the event of a security breach, theft, or loss of a device containing the Access Right, resulting in potential unauthorized disclosure Player must immediately notify Playporn.bet or the Site of said security breach.</p>

          <h2 className={styled.subHeader}>SANCTION AND APPROVAL OF ADULT MATERIAL</h2>
          <p className={styled.text}>This Site contains age-restricted materials. If Player is under the age of 18 years, or under the age of majority in the location from where accessing this Site Player does not have authorization or permission to enter or access any of its materials. If Player is over the age of 18 years and over the age of majority in the location from where accessing this site by entering the website Player hereby agrees to comply with these terms and conditions.</p>

          <h2 className={styled.subHeader}>SUPPLEMENTARY TERMS AND CONDITIONS</h2>
          <p className={styled.text}>The Site may have additional Terms and Conditions that are an integral part of its offering to Player, and are in addition to the Terms and Conditions here. Any such Terms and Conditions as listed on the site will in no way invalidate any of the Terms and Conditions listed here. This Agreement shall be construed and enforced in accordance with the laws of Cyprus. Disputes arising hereunder shall be brought in either Cyprus court.</p>

          <h2 className={styled.subHeader}>SEVERABILITY</h2>
          <p className={styled.text}>If any provision of this Agreement shall be held invalid or unenforceable for any reason, the remaining provisions shall continue to be valid and enforceable. If a court finds that any portion of this Agreement is invalid or unenforceable, but that by limiting such provision it would become valid or enforceable, then such provision shall be deemed to be written, construed, and enforced as so limited.</p>

          <h2 className={styled.subHeader}>NOTICE</h2>
          <p className={styled.text}>
            Notices by the Site to Players may be given by means of electronic messages through the Site, by a general posting on the Site, or email. Notices by Players may be given by email, unless otherwise specified in the Agreement. All questions, complaints, or notices regarding the site must be directed to Playporn.bet.<br />
            Questions and Contact Information All questions to Playporn.bet regarding these terms and conditions must be directed to support@playporn.bet
          </p>

          <h2 className={styled.subHeader}>DISCLAIMER</h2>
          <p className={styled.text}>
            Player understands that the Site cannot and does not guarantee or warrant that files available for downloading from the Internet will be free of viruses, worms, trojan horses or other code that may manifest contaminating or destructive properties. Player is responsible for implementing sufficient procedures and checkpoints to satisfy Player’s particular requirements for accuracy of data input and output, and for maintaining a means external to the site for the reconstruction of any lost data. Site does not assume any responsibility or risk for Player’s use of the Internet. However, in the event Site learns of such a breach, Site will notify Player so that Player can take the necessary precautions.<br />
            Player’s use of the Site is at his or her own risk and the content is provided “as is” - without warranties of any kind, either expressed or implied. Site disclaims all warranties including any implied warranties of merchantability, fitness for a particular purpose, title or non-infringement. Site does not warrant that the functions or content contained in the Site will be uninterrupted or error-free, that defects will be corrected, or that the Site or the server that makes it available are free of viruses or other harmful components.<br />
            The Site does not warrant or make any representation regarding use, or the result of use, of the content in terms of accuracy, reliability, or otherwise. The content may include technical inaccuracies or typographical errors, and the Site may make changes or improvements at any time. The Site does not warrant or make any representations regarding the appropriateness of the material or content or the authorization for use in all countries, states, provinces, counties or any other jurisdictions. If Player chooses to access the Site, Player does so at its own risk, and subject to the laws in its own jurisdiction. Player is responsible for compliance with all applicable laws.
          </p>

          <h2 className={styled.subHeaderB}>CONTENT COMPLIANCE RULES</h2>
          <p className={styled.text}>Examples of the types of content that violate this policy include, but are not limited to:</p>
          <ul className={styled.list}>
            <li>Videos or photos of any person, whether in a public or private setting, without that person’s valid written consent (such as hidden/spy camera content, or up-skirt content);</li>
            <li>Sharing explicit sexual images of someone else without their valid written consent (this would include, namely: non-consensual distribution of intimate images, also commonly referred to as “revenge porn”);</li>
            <li>Sharing non-sexually explicit images of someone else without their valid written consent;</li>
            <li>“Doxing”, defined as exposing private and identifying information about a particular individual, including phone number, e-mail, address, or full name.</li>
          </ul>
          <p className={styled.text}>To help protect the integrity of the platform, we also prohibit content, including fictional, simulated, or animated, that features or depicts*:</p>
          <ul className={styled.list}>
            <li>Depictions of non-consensual sexual activity, including forced sexual acts, rape, or sexual assault;</li>
            <li>Persons appearing drugged, incapacitated, or intoxicated* involved in sexual acts;</li>
            <li>Any “deepfakes” whatsoever (manipulating a person’s image, whether in picture or video, to deceive or mislead the viewer into believing that person is acting or speaking in the way presented, absent artistic merit).</li>
          </ul>
        </Col>

        <Col className={styled.footer}>
          <p className={styled.footnote}>* Intoxicated (including pretending to be) means a state induced by alcohol or drugs, whether legal, illicit, or recreational as determined by Playporn.bet in its sole discretion, such that a performer’s judgment appears to the reasonable observer as objectively impaired.</p>
          <p>
            Our compliance department always checks model and studio agreements, reputation of counter parties, ID’s and other necessary data: (age 18+, consent of persons depicted in video) before we download it on Playporn.bet website platform.<br />
            Any video content only can be uploaded after acceptance from the compliance department and moderators - four eyes control.<br />
            After request from model or studio (video content owner, or depicted person in video) video is being removed from website.<br />
            Our team of moderators and support staff work 24 hours a day, 7 days a week in order to review all uploaded content for violations of our Terms of Service, address user concerns, and remove all non-consensual content that we identify or of which we are made aware.<br />
            Also moderators review all the complains received from game Players including the doubtful content on Playporn.bet.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export { Terms };
