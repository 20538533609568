import React from "react";

import styled from './MyMusic.module.scss'
import trophy from '../../assets/musicbet/trophy.svg'
import income from '../../assets/musicbet/work_update.svg'

interface MyMusicProps {
 image: string,
 genre: string,
 artist: string,
 track: string,
 year: number,
 price: number,
 win: number,
 income: number
}

const MyMusic = (props: MyMusicProps) => {
 return (
  <div className={styled.wrapper}>
   <div className={styled.info_wrapp}>
    <div className={styled.image_wrapper}>
     <img src={props.image} className={styled.image} alt="cover"/>
    </div>
    <div className={styled.info_content}>
     <p>Genre: {props.genre}</p>
     <p>Artist: {props.artist}</p>
     <p>Track: {props.track}</p>
     <p>Year: {props.year}</p>
     <p>Price: {props.price}</p>
    </div>
   </div>
   <div className={styled.additional}>
    <div>
     <p className={styled.additional_p}>Track Win / Income</p>
    </div>
    <div className={styled.trohpeys}>
     <div className={styled.trophy}>
      <img src={trophy} alt="tophy" />
      <p>{props.win}</p>
     </div>
     <div className={styled.trophy}>
      <img src={income} alt="income" />
      <p>{props.income}</p>
     </div>
    </div>
   </div>
  </div>
 )
}

export default MyMusic;
