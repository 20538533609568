import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';

import Timer from '../../Timer';
import { SongCard } from '../../SongCard/SongCard';
import vs from '../../../assets/VS.png'

import s from '../BattleList.module.scss';
import Reveal, { Slide } from 'react-awesome-reveal';

import { keyframes } from "@emotion/react";
import MusicBattleItem from '../../BattleItem';
import Comments from '../../VideoPlayer/Comments';

const customAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;


interface IProps {
    battle: any;
    idxBattle: number;
    currentVideoIdx?: number;
    currentBattleIdx?: number;
    battleEndTime: string;
    winnerCustomerId: number;
    battleId: number;
    pick?: any
    setPick?: any
    categoryId: number
    withoutPlay?: boolean

    type?: "string"
}

const BattleItem: React.FC<IProps> = ({
    battle,
    idxBattle,
    currentBattleIdx,
    winnerCustomerId,
    battleId,
    pick,
    setPick,
    categoryId,
    withoutPlay,
    // style = [{}, {}]
}) => {



    const navigate = useNavigate();
    const [videos, setVideos] = useState<any[]>([]);

    const [isVoted, setISVoted] = useState(false);
    console.log(isVoted)
    // const [archive, setArchive] = useState<any>()

    const { battle_customers } = battle

    const isMobile = useMediaQuery({
        query: "(max-width: 992px)",
    });

    useEffect(() => {
        if (battle && battle_customers) {
            const battleVideos = battle_customers
                .map(({ track, id, customer }: { track: any[], id: number, customer: any[] }) => {
                    return { ...track, id, battleId: battle.id, customer: { ...customer } };
                });

            setVideos(battleVideos);
        }
    }, [battle, battle_customers, pick?.touch]);

    let songRefs: any = [];

    function redirectToCurrentBasttle(id): void {
        // type = "archive" ? navigate(`archive/?id=${id}`)  :
        navigate(`/battle/?id=${id}`);
    }

    const location = useLocation();

    if (videos) {

        return (
            <>
            <div className={s.timer_wrapper}>
            <Timer date={undefined}/>
            </div>
                <Row style={{flexDirection: 'row'}}>

                    {
                        videos.map((video: any, idx: number) => {
                            return (
                                <Col md={12}>
                                    <Slide delay={100} direction={idx === 0 ? 'down' : "up"}>
                                        <Reveal delay={100} keyframes={customAnimation}>

                                                {/* <SongCard
                                                    songRef={(ref: any) => (songRefs[idx] = ref)}
                                                    idxBattle={idxBattle}
                                                    currentBattleIdx={currentBattleIdx}
                                                    video={video}
                                                    key={video.id}
                                                    setISVoted={setISVoted}
                                                    idx={idx}
                                                    battleId={battleId}
                                                    pick={pick}
                                                    setPick={setPick}
                                                    withoutPlay={withoutPlay}
                                                    archive={location.pathname === '/history' ? true : false}
                                                /> */}
                                                <MusicBattleItem/>

                                        </Reveal>
                                    </Slide>
                                </Col>
                            )
                        })
                    }
                </Row>
                <div className={s.comments_wrapper}>
                <Comments videoId={undefined} />
                </div>
            </>
        )
    }

    return <></>
};

export { BattleItem };
