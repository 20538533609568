/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { SongInfo } from "./SongInfo/SongInfo";
import { SongPoster } from "./SongPoster/SongPoster";


import styled from "./SongCard.module.scss";
import { useMediaQuery } from "react-responsive";
import { Col, Row } from "antd";
// import { videoAPI } from "../../api/api";
import { Fade, Slide } from "react-awesome-reveal";

interface IProps {
    // song: Song;
    video: any;
    setISVoted: (v: boolean) => void;
    idx: number;
    idxBattle: number;
    currentBattleIdx?: number;
    songRef: any;
    battleId?: number;
    pick: any;
    setPick: any;
    withoutPlay?: boolean;
    archive?: boolean
    type?: string
    likes?: any
    battle?: any
    handleLike?: any
    onBattleUpdate?: any
}

const SongCard: React.FC<IProps> = ({
    video,
    setISVoted,
    idxBattle,
    currentBattleIdx,
    idx,
    songRef,
    battleId,
    pick,
    setPick,
    withoutPlay,
    archive,
    type,
    likes,
    battle,
    handleLike,
    onBattleUpdate
}) => {
    const dispatch = useAppDispatch();
    const [isPlaying, setIsPlaying] = useState(false);

    // const id = useAppSelector((state) => state.player.songId);
    const selectedCategoryId = useAppSelector(
        (state) => state.musicCategories.selectedCategory
    );
    const [initialZindex, setInitialZindex] = useState(null);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (containerRef.current && initialZindex === null) {
            setInitialZindex(containerRef.current.style.zIndex);
        }
    }, [containerRef, initialZindex]);

    const videoGenre = useMemo(() => {
        if (selectedCategoryId === null) {
            const category = video.categories_names[0];
            return category ? category.name : "Others";
        }
        const category = video.categories_names.find((category: any) => {

            return category.id === selectedCategoryId
        });
        return category ? category.name : "Others";
    }, [selectedCategoryId, video.music_categories]);


    const isMobile = useMediaQuery({
        query: "(max-width: 720px)",
    });
    const isDesktop = useMediaQuery({
        query: "(min-width: 1200px)",
    });

    const handleActive = () => { };

    const voteData = {
        video,
        setISVoted,
    };

    return (
            <Row>
                <Col span={24}>
                    <SongPoster
                        isPlaying={isPlaying}
                        videoData={video}
                        voteData={voteData}
                        battleId={battleId}
                        pick={pick}
                        setPick={setPick}
                        withoutPlay={withoutPlay}
                        idx={idx}
                        onBattleUpdate={onBattleUpdate}

                    />
                </Col>
                {!withoutPlay && <Col span={24}>
                    <SongInfo
                        setPick={setPick}
                        pick={pick}
                        video={video}
                        title={video.label}
                        singer={video.name}
                        setISVoted={setISVoted}
                        id={video.id}
                        battleId={battleId}
                        archive={archive}
                        type={type}
                        likes={likes}
                        battle={battle}
                        handleLikes={() => handleLike()}
                    />
                </Col>}
            </Row>
    );
};

export { SongCard };
