import styled from './Battle.module.scss'


import MusicHeader from "../../components/MusicHeader";
import BattleResultsItem from "../../components/BattleResultsItem";

import mockCover from '../../assets/mock/Battle_mokup 2.png'
import { List } from 'antd';
import { PaginationAlign, PaginationPosition } from 'antd/es/pagination/Pagination';

const BattleArchive = ({ onlyResults }: { onlyResults?: boolean }) => {
  const mock = [
    {id: 1, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover},
    {id: 2, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover},
    {id: 3, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover},
    {id: 4, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover},
    {id: 5, tournament: 'Pop', end_date: '22.11.2024', winner: "Doja Cat", prizePull: 2000, winningPlayers: 100, cover: mockCover},

  ]

  return (
    <>
    <MusicHeader title="BATTLE RESULTS" primaryColor="#F56040" color="#fff"/>
    <div className={styled.content}>
    <List
        pagination={
          {position: 'bottom', align:'center'}
        }

        dataSource={mock}
        renderItem={(item, index) => (
          <BattleResultsItem
          index={index}
          genre={item.tournament}
          ended_at={item.end_date}
          winner={item.winner}
          prize={item.prizePull}
          cover={item.cover}
          players={item.winningPlayers}
          battle={item.id}
          />
        )}
      />

    </div>
    </>
  )
}

export default BattleArchive;
