import React from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './Genres.module.scss'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Genres = () => {

 const navigate = useNavigate();

 const mocks = [
  { id: 1, genre: "Pop", primary_color: '#FF75E6' },
  { id: 2, genre: 'Latin', primary_color: '#FF4B00' },
  { id: 3, genre: "Rock", primary_color: '#F12027' },
  { id: 4, genre: "Hip-Hop", primary_color: '#4F4F2E' },
  { id: 5, genre: 'R&B', primary_color: '#9507FF' },
  { id: 6, genre: 'Country', primary_color: '#A8908D' },
  { id: 7, genre: 'EDM', primary_color: '#BAD131' },
  { id: 8, genre: 'Jazz', primary_color: '#174BEB' },
  { id: 9, genre: 'Classic', primary_color: '#BAAFDD' },
 ]


 return (
  <div className={styles.wrapper}>

    <MusicHeader title="TOURNAMENT GENRES" primaryColor="#B8B8C0" />

   <div className={styles.buttons}>
    {mocks.map((item) => {
     return (
      <Button onClick={() => { navigate('/battle/tournament') }} className={styles.genre_button} style={{ background: `${item.primary_color}` }}>{item.genre}</Button>
     )
    })}
   </div>
  </div>
 )
}

export default Genres;
