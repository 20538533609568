import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from './ProfileBtn.module.scss';
import { Button } from 'antd';

interface IProps {
  title: string;
  isActive: number;
  setIsActive: (index: number) => void;
  index: number;
  svg: JSX.Element;
  url: string;
}

const ProfileBtn: React.FC<IProps> = ({ url, title, svg, isActive, setIsActive, index }) => {

  const navigate = useNavigate();

  return (
    <Button
    className={isActive === index ? styled.button_active : styled.button}
      type={"default"}
      onClick={() => navigate(url)}
    >
<div className={styled.button_new}>

          <div className={isActive === index ? styled.activeIcon : styled.icon}>{svg}</div>

          <span className={isActive === index ? styled.activeText : styled.text}>{title}</span>
</div>

    </Button>
  );
};

export { ProfileBtn };
