import React from "react";
import styles from './BattleItem.module.scss'
import mock from '../../assets/mock/battle.jpg'
import { useNavigate } from "react-router-dom";

interface ItemProps {
 battle?: string,
 image?: any,
 primaryColor?: string,
 secondaryColor?: string
 id?: any
}

const MusicBattleItem = (props: ItemProps) => {
 const navigate = useNavigate()
 return (
  <div onClick={() => navigate(`/battle/?id=${props.id}`)} className={styles.wrapper}>
   <div className={styles.image_container}>
    <img className={styles.cover} src={mock} alt="battle" />
   </div>
   <div>
    <div className={styles.big_under}>
     <h2>BATTLE #1</h2>
    </div>
    <div className={styles.small_under}>
     <h2>08.10.2024</h2>
    </div>
   </div>


  </div>
 )
}

export default MusicBattleItem;
