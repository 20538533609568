import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProfileBtn } from '../Buttons/ProfileBtn/ProfileBtn';

import { ReactComponent as BuyTokensIcon } from '../../assets/musicbet/account/poker_chip.svg'
import { ReactComponent as BattleHistoryIcon } from '../../assets/musicbet/account/electric_bolt.svg'
import { ReactComponent as WithdrawIcon } from '../../assets/musicbet/account/mintmark.svg'
import { ReactComponent as MyMusicIcon } from '../../assets/musicbet/account/queue_music.svg'
import { ReactComponent as ContactActive } from '../../assets/musicbet/contact_support_active.svg'


import styled from './ProfileMenu.module.scss';
import { useMediaQuery } from 'react-responsive';

const ProfileMenu = (active?: any) => {
  const [activeBtn, setActiveBtn] = useState(null);
  const location = useLocation();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  useEffect(() => {
    if (location.pathname.includes('buy-tokens')) setActiveBtn(0);
    if (location.pathname === ('/profile/battle-history')) setActiveBtn(1);
    if (location.pathname.includes('withdraw') && isDesktop) setActiveBtn(3);
    if (location.pathname.includes('withdraw') && !isDesktop) setActiveBtn(2);
    if (location.pathname.includes('my-music') &&  isDesktop) setActiveBtn(2);
    if (location.pathname.includes('my-music') &&  !isDesktop) setActiveBtn(3);
    if (location.pathname.includes('help')) setActiveBtn(4)
  }, [location.pathname]);

  console.log(location.pathname)

  const buttons = [
    { title: 'Buy Tokens', url: '/profile/buy-tokens', svg: <BuyTokensIcon /> },
    { title: 'Battle History', url: '/profile/battle-history', svg: <BattleHistoryIcon /> },
    { title: 'Withdraw', url: '/profile/withdraw', svg: <WithdrawIcon /> },
    { title: 'My Music', url: '/profile/my-music', svg: <MyMusicIcon /> },
  ];

  const desktopButtons = [
    { title: 'Buy Tokens', url: '/profile/buy-tokens', svg: <BuyTokensIcon /> },
    { title: 'Battle History', url: '/profile/battle-history', svg: <BattleHistoryIcon /> },
    { title: 'My Music', url: '/profile/my-music', svg: <MyMusicIcon /> },
    { title: 'Withdraw', url: '/profile/withdraw', svg: <WithdrawIcon /> },
    { title: "Help", url: '/profile/help', svg: <ContactActive /> }
  ]

  if (!isDesktop) {
    return (
      <div className={styled.container}>

        {buttons.map((el, index) => {
          return (
            <ProfileBtn
              url={el.url}
              key={index}
              index={index}
              setIsActive={setActiveBtn}
              isActive={activeBtn}
              title={el.title}
              svg={el.svg}
            />
          );
        })}
      </div>
    );
  }
  else {
    return (
      <div className={styled.container}>

        {desktopButtons.map((el, index) => {
          return (
            <ProfileBtn
              url={el.url}
              key={index}
              index={index}
              setIsActive={setActiveBtn}
              isActive={activeBtn}
              title={el.title}
              svg={el.svg}
            />
          );
        })}
      </div>
    );
  }
};

export { ProfileMenu };
