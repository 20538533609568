import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Drawer, Row } from 'antd';

import { closeMobileMenu } from '../../store/mobileMenuSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import styled from './MobileMenu.module.scss';
import { ReactComponent as Logo } from '../../assets/musicbet/MusicbetLogo.svg'
import { ReactComponent as Cancel } from '../../assets/musicbet/cancel.svg'
import MusicHeader from '../MusicHeader';

import { ReactComponent as AccountIcon } from '../../assets/musicbet/menu_icons/account.svg'
import { ReactComponent as BattlesResultIcon } from '../../assets/musicbet/menu_icons/battleResults.svg'
import { ReactComponent as BattlesIcon } from '../../assets/musicbet/menu_icons/battles.svg'
import { ReactComponent as ContactUsIcon } from '../../assets/musicbet/menu_icons/contactus.svg'
import { ReactComponent as GameplayIcon } from '../../assets/musicbet/menu_icons/gameplay.svg'
import { ReactComponent as HallOfFameIcon } from '../../assets/musicbet/menu_icons/halloffame.svg'
import { ReactComponent as Marketplace } from '../../assets/musicbet/menu_icons/marketplace.svg'

import { ReactComponent as Instagram } from '../../assets/musicbet/menu_icons/social_media/_Instagram.svg'
import { ReactComponent as Twitter } from '../../assets/musicbet/menu_icons/social_media/_Twitter.svg'
import { ReactComponent as Telegram } from '../../assets/musicbet/menu_icons/social_media/_Telegram.svg'
import { ReactComponent as Youtube } from '../../assets/musicbet/menu_icons/social_media/_YouTube.svg'
import { ReactComponent as Tiktok } from '../../assets/musicbet/menu_icons/social_media/_TikTok.svg'
import { ReactComponent as Spotify } from '../../assets/musicbet/menu_icons/social_media/_Spotify.svg'
import { ReactComponent as LogoutIcon } from '../../assets/musicbet/menu_icons/logout.svg'

import { SignIn } from '../../pages/Login/SignIn';
import { authAPI, profileAPI } from '../../api/api';
import { userLogOut } from '../../store/isAuthSlice';
import { closeLoginModalMenu, openLoginModalMenu } from '../../store/loginModalSlice';
import { NotificationT } from '../ToastifyNot/NotificationToastify';
import { setUserData } from '../../store/playerSlice';



// const social = [
//   { name: "twitter", url: "https://twitter.com/Playporn_bet", svg: <TwitterX style={{ width: 29 }} /> },
// ]

const menuButtons = [
  { title: "Battles", slug: "/genres", icon: <BattlesIcon /> },
  { title: "Battle Results", slug: "/battle-results", icon: <BattlesResultIcon /> },
  { title: "Hall of Fame", slug: "/hall-of-fame", icon: <HallOfFameIcon /> },
  { title: "Marketplace", slug: "/marketplace", icon: <Marketplace /> },
  { title: "Gameplay", slug: "/gameplay", icon: <GameplayIcon /> },
  { title: "Contact Us", slug: "/contacts", icon: <ContactUsIcon /> }
]

const socialMediaButtons = [
  { title: "Instagram", slug: "", icon: <Instagram /> },
  { title: "Twitter", slug: "", icon: <Twitter /> },
  { title: "Telegram", slug: "", icon: <Telegram /> },
  { title: "Youtube", slug: "", icon: <Youtube /> },
  { title: "Tik Tok", slug: "", icon: <Tiktok /> },
  { title: "Spotify", slug: "", icon: <Spotify /> },
]



const MobileMenu = () => {


  const [isAccountOpen, setAccoutnOpen] = useState(false)
  const isOpen = useAppSelector((state) => state.mobileMenu.isOpen);
  const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);
  const isAuth = useAppSelector((state) => state.isAuth.isAuth)
  console.log(isAuth)
  const navigate = useNavigate();

  console.log("isAuth", isAuth)



  const dispatch = useAppDispatch();

const logout = () => {
		authAPI
			.logOut()
			.then(() => {
				dispatch(userLogOut(null));
			})
      .then(() => {
        profileAPI
				.getProfile()
				.then((res) => {
					dispatch(
						setUserData({
							first_name: res.first_name,
							last_name: res.last_name,
							nickname: res.nickname,
							email: res.email,
							gender: res.gender,
							phone: res.phone,
							id: res.id,
							balance: res.amount,
							avatar: res?.avatar,
							first_voted: res?.data?.first_voted,
							income: res?.transaction_sums?.income,
							expense: res?.transaction_sums?.expense,
							transactions: res?.videobet_transactions,
							stocks: res?.stocks
						})
					)
				});
      })
			.then(() => localStorage.removeItem('isAuth'))
      .then(() => userLogOut())
      .then(() => navigate('/genres'))
	}


  return (
    <div className={styled.mobileMenu}>
        <nav className={styled.mobileNavigationWrapper}>
          <div className={styled.iconsWrapper}>
            <div className={styled.menu_logo}>
              <Logo className={styled.logo} />
            </div>
            <div className={styled.menu_actions}>
              <Button className={styled.cancel_btn}
                type='link'
                onClick={() => {
                  dispatch(closeMobileMenu(null))
                }}
              ><Cancel className={styled.cancel} /></Button>
            </div>
            {/* <div className={styled.userInfoIconsWrapper}>
              {!isAuth && <User className={styled.navIcons} onClick={() => openModal()} title='Sign Up Free' />}
            </div> */}
          </div>
          <div>
            <MusicHeader title='Menu' primaryColor={"#CEC7AE"} />
          </div>

          {/* main */}
          <div className={styled.mainNavigation}>
            <>
              <Button
                onClick={() => {
                  if (isAuth) {
                    // navigate('/login');
                    navigate('/profile')
                    dispatch(closeMobileMenu(null))
                  } else {
                    dispatch(openLoginModalMenu(null))
                  }
                }}
                className={styled.button}
              >
                <Row gutter={[10, 10]} align={'middle'} className={styled.bthContent}>
                  <Col style={{ display: 'flex' }}><AccountIcon /></Col>
                  <Col><span>Account</span></Col>
                </Row>
              </Button>
              {
                menuButtons.map((menuItem) => {
                  return (
                    <Button
                      onClick={() => {
                        dispatch(closeMobileMenu(null))
                        navigate(menuItem.slug)
                      }}
                      className={styled.button}
                    >
                      <Row gutter={[10, 10]} align={'middle'} className={styled.bthContent}>
                        <Col style={{ display: 'flex' }}>{menuItem.icon}</Col>
                        <Col><span>{menuItem.title}</span></Col>
                      </Row>
                    </Button>
                  )
                })
              }

              <div className={styled.social_media_block}>
                {
                  socialMediaButtons.map((socialMediaItem) => {
                    return (
                      <Button
                        onClick={() => {
                          dispatch(closeMobileMenu(null))
                          navigate(socialMediaItem.slug)
                        }}
                        type='link'
                        className={styled.sm_icons}
                      >

                        <div>{socialMediaItem.icon}</div>

                      </Button>
                    )
                  })
                }
              </div>

              {isAuth ?
                <div style={{ width: '100%' }}>
                  <Button
                    onClick={() => {
                      dispatch(closeMobileMenu(null))
                    logout()
                    }}
                    className={styled.button}
                  >
                    <Row gutter={[10, 10]} align={'middle'} className={styled.bthContent}>
                      <Col style={{ display: 'flex' }}><LogoutIcon /></Col>
                      <Col><span>Log out</span></Col>
                    </Row>
                  </Button>
                </div>
                :
                <></>
                }

              <div className={styled.footer} style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/terms-n-conditions')
                  }}
                  className={styled.footer_btn}>Terms & Conditions</Button>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/privacy')
                  }}
                  className={styled.footer_btn}>Privacy Policy</Button>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/cookie')
                  }}
                  className={styled.footer_btn}>Cookie Policy</Button>
                <p className={styled.copyright}>© 2024 Music.bet</p>
              </div>

            </>
          </div>
        </nav>
      <Drawer
        open={isOpenModalLogin}
        style={{ minHeight: "100vh", width: "100%" }}
      >
        <div className={styled.mobileMenu}>

          <nav className={styled.mobileNavigationWrapper}>
            <div className={styled.iconsWrapper}>
              <div className={styled.menu_logo}>
                <Logo className={styled.logo} />
              </div>
              <div className={styled.menu_actions}>
                <Button className={styled.cancel_btn}
                  type='link'
                  onClick={() => {
                    dispatch(closeLoginModalMenu(null))
                  }}
                ><Cancel className={styled.cancel} /></Button>
              </div>
              {/* <div className={styled.userInfoIconsWrapper}>
              {!isAuth && <User className={styled.navIcons} onClick={() => openModal()} title='Sign Up Free' />}
            </div> */}
            </div>
            <div className={styled.login_drawer}>
              <NotificationT/>
              <SignIn />

              <div className={styled.footer_login} style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/terms-n-conditions')
                  }}
                  className={styled.footer_btn}>Terms & Conditions</Button>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/privacy')
                  }}
                  className={styled.footer_btn}>Privacy Policy</Button>
                <Button
                  onClick={() => {
                    dispatch(closeMobileMenu(null))
                    navigate('/cookie')
                  }}
                  className={styled.footer_btn}>Cookie Policy</Button>
                <p className={styled.copyright}>© 2024 Music.bet</p>
              </div>
            </div>
          </nav>
        </div>

      </Drawer>
    </div>
  );
};

export { MobileMenu };
