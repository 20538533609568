import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { profileAPI } from '../../api/api';

import styled from './ProfileLayout.module.scss';
import { Button, Col, Input, Row } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setUserData } from '../../store/playerSlice';
import { ReactComponent as Wallet } from '../../assets/musicbet/credit_card_heart.svg'
import { ReactComponent as WalletActive } from '../../assets/musicbet/credit_card_heart_active.svg'
import { ReactComponent as Contact } from '../../assets/musicbet/contact_support.svg'
import { ReactComponent as ContactActive } from '../../assets/musicbet/contact_support_active.svg'
import { ReactComponent as DeleteImage } from '../../assets/musicbet/account/1.svg'
import MusicHeader from '../MusicHeader';
import { useMediaQuery } from 'react-responsive';



interface IProps {
  children?: any;
}

const ProfileLayout: React.FC<IProps> = ({ children }) => {
  //const [userData, setUserData] = useState<any>({});
  const dispatch = useAppDispatch();
  const { userId, nickname, email, avatar } = useAppSelector((state) => state.player);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });


  const [setTransactionSum] = useState<any>({});
  //const [balance, setBalance] = useState<any>(0);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(true);

  const [active, setActive] = useState(null)

  const [isEditMode, setEditMode] = useState(false);

  const getUserData = () => {
    profileAPI
      .getProfile()
      .then((res) => res)
      .then((res) => {

        dispatch(
          setUserData({
            first_name: res.first_name,
            last_name: res.last_name,
            nickname: res.nickname,
            email: res.email,
            gender: res.gender,
            phone: res.phone,
            id: res.id,
            balance: res.amount,
            avatar: res?.avatar,
            first_voted: res?.data?.first_voted,
            income: res?.transaction_sums.income,
            expense: res?.transaction_sums.expense,
            transactions: res?.videobet_transactions,
            player_status: res?.player_status,
            giver_status: res?.giver_status,
            sponsor_status: res?.sponsor_status,
            stocks: res?.stocks
          })
        )
      });
  }

  const location = useLocation();

  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (localStorage.getItem('isAuth')) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    if (location.pathname.includes('bonus')) setActive(0);
    if (location.pathname.includes('help')) setActive(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuth) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  useEffect(() => {
    if (userId === 0) {
      profileAPI.getProfile().then((res) => {
        setTransactionSum(res.data.transactions_sum);
        setUserData(res.data.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <div>
      <Row >
        <Col span={24}>
          <MusicHeader title='Account' primaryColor='#9507FF' color='#fff' />
        </Col>
      </Row>
      <Row justify={'space-between'} className={styled.container}>
        <Col xs={24} lg={10} xl={6} xxl={4}>
          <Row justify={'space-between'}>
            <Col span={10}>
              <div className={styled.avatar_wrapp}>
                {
                  <>
                    <Button style={isEditMode ? { display: 'block' } : { display: 'none' }} type='link' className={styled.delete_btn}><DeleteImage /></Button>
                    {
                      !!avatar && <img className={styled.avatar} src={avatar} alt={""} />
                    }
                  </>
                }
              </div>
            </Col>
            {isDesktop ?
              null :
              <Col span={13}>
                <span className={styled.nickname}>{nickname}</span>
                <Input disabled={isEditMode ? false : true} placeholder={nickname} className={styled.input_edit} />
                <Input disabled={isEditMode ? false : true} placeholder={email} className={styled.input_edit} />
              </Col>
            }

          </Row>
          <Row>
            <Col span={24} className={styled.profile_btns}>
              <Button style={{ width: "100%" }} onClick={() => navigate('/profile/bonus')} className={active === 0 ? styled.bonus_btn_active : styled.bonus_btn}>
                {
                  active === 0 ? <WalletActive className={styled.bonus_icon} /> : <Wallet className={styled.bonus_icon} />
                }
                <span>Bonus</span></Button>
              {!isDesktop ?
                <Button onClick={() => navigate('/profile/help')} className={active === 1 ? styled.help_btn_active : styled.help_btn}>
                  {active === 1 ? <ContactActive className={styled.bonus_icon} /> : <Contact className={styled.bonus_icon} />}
                  <span>Help</span>
                </Button>
                : null}
              {
                !isDesktop ? <Button onClick={() => { isEditMode ? setEditMode(false) : setEditMode(true) }} className={isEditMode ? styled.edit_btn_active : styled.edit_btn}>{isEditMode ? "Save" : "Edit"}</Button> : null
              }

            </Col>
          </Row>
          <ProfileMenu />
        </Col>
        <Col xs={24} lg={13} xl={{ span: 15}} xxl={16}>
          <div className={styled.edit_desktop}>

          <span className={styled.nickname}>{nickname}</span>

          <Input disabled={isEditMode ? false : true} placeholder={nickname} className={styled.input_edit} />
          <Input disabled={isEditMode ? false : true} placeholder={email} className={styled.input_edit} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: "100%" }}>
            <Button style={{ marginBottom: 60, marginLeft: 0, width: 120 }} onClick={() => { isEditMode ? setEditMode(false) : setEditMode(true) }} className={isEditMode ? styled.edit_btn_active : styled.edit_btn}>{isEditMode ? "Save" : "Edit"}</Button>
          </div>
          </div>
          <div className={styled.child}>
          {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { ProfileLayout };
