import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
/* import { ReactComponent as Logo } from '../../assets/logoPlayporn.png'; */

import { closeMobileMenu, openMobileMenu } from '../../store/mobileMenuSlice';
import { MobileMenu } from '../mobileMenu/MobileMenu';
import {ReactComponent as Logo}  from '../../assets/musicbet/MusicbetLogo.svg';
import logosvg from '../../assets/musicbet/MusicbetLogo.svg'
import MenuIcon from '../../assets/musicbet/menu.svg'
import { sprintf } from 'sprintf-js'
import { ReactComponent as Balance } from '../../assets/svg/account_balance.svg'

import styled from './Layout.module.scss';

import { LoginModal } from "../../pages/Login/LoginModal/LoginModal";
import { Button, Col, Drawer, Row } from 'antd';
import Account from '../Account';
import MusicHeader from '../MusicHeader';

interface IProps {
    children: JSX.Element;
}

const Layout: React.FC<IProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState({ text: '', primaryColor: '', color: '' })
    useEffect(() => {


        if (location.pathname.includes('acctount')) {
            setCurrentPage({ text: 'Account', primaryColor: '#9507FF', color: "#fff" })
        } else if (location.pathname.includes('genres')) {
            setCurrentPage({ text: 'Tournament geners', primaryColor: '#B8B8C0', color: "#fff" })
        } else if (location.pathname.includes('tournament')) {
            setCurrentPage({ text: 'Pop tournament', primaryColor: '#FF75E6', color: "#fff" })
        } else if (location.pathname.includes('history')) {
            setCurrentPage({ text: 'Battle results', primaryColor: '#F56040', color: "#fff" })
        } else if (location.pathname.includes('hall-of-fame')) {
            setCurrentPage({ text: 'Hall of fame', primaryColor: '#FFD300', color: "#fff" })
        } else if (location.pathname.includes('marketplace')) {
            setCurrentPage({ text: 'marketplace', primaryColor: '#EC1F26', color: "#fff" })
        } else if (location.pathname.includes('gameplay')) {
            setCurrentPage({ text: 'gameplay', primaryColor: '#00A91F', color: "#fff" })

        }
    }, [location.pathname])

    const { balance } = useAppSelector((state) => state.player);
    const isAuth = useAppSelector((state) => state.isAuth.isAuth)

    // function openProfile() {
    //     navigate('/customer-tracks');
    // }
    const dispatch = useAppDispatch();
    const isOpenMobileMenu = useAppSelector((state) => state.mobileMenu.isOpen);


    const isDesktop = useMediaQuery({
        query: '(min-width: 1200px)',
    });
    return (
        <div className={styled.container}>
            {location.pathname.includes('login') ? <Logo className={styled.big_logo} /> : <></>}
            <div className={styled.layout_wrapper}>
                {isAuth && <Account isAuth={isAuth} />}
                {!isDesktop && (
                    <div>
                        <div style={{ width: "100%" }}>
                            {
                                !isAuth && (
                                    <Row gutter={[10, 10]} style={{ marginTop: 5 }}>
                                        <Col span={24} className={styled.logo_col}>
                                            <div className={styled.logo_col}>
                                                <Button className={styled.button_link} type="link" href="#/game-rules">
                                                    <img className={styled.logoAnonim} src={logosvg} alt='' style={{ marginTop: 8 }} width={150} />
                                                </Button>
                                            </div>

                                        </Col>
                                    </Row>
                                )
                            }
                        </div>


                    </div>
                )}

                <Drawer
                    placement={"top"}
                    style={{ minHeight: "100vh" }}
                    onClose={() => dispatch(closeMobileMenu(null))}
                    open={isOpenMobileMenu}
                >
                    <MobileMenu />
                </Drawer>
                <div className={styled.logo_col}>
                    {isDesktop ? <></> :
                        <Button className={styled.button_wallet} type={"link"} onClick={() => navigate('/profile/buy-tokens')}>

                            <div className={styled.button_wrapper}>
                                <Balance style={{ fill: '#fff', width: "30px", height: 'auto' }} />
                                <span style={{ paddingLeft: 10 }}>
                                    {sprintf('%.2f', balance)}</span>
                            </div>
                        </Button>
                    }
                    {
                        isDesktop ? <MusicHeader primaryColor={currentPage.primaryColor} title={currentPage.text} color={currentPage.color} /> : <></>
                    }

                </div>
                {!isDesktop ?
                    <img
                        className={styled.menuIconOpen}
                        onClick={() => dispatch(openMobileMenu(null))}
                        src={MenuIcon}
                        alt='menu'
                    />
                    :
                    <></>}
            </div>
            {/* {isOpenModalLogin && <LoginModal />} */}
            <div className={styled.wrapper}>
                <>{children}</>
            </div>


        </div>
    );
};

export { Layout };