import React from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import { Table, TableProps } from "antd";
import styled from './BattleHistory.module.scss'
import { ReactComponent as Swap } from '../../../assets/musicbet/swap_vert.svg'
import mockImg from '../../../assets/mock/Battle_mokup 2.png'
interface DataType {
  id: number;
  battle: any;
  bets: {
    bet: number;
    lose: number;
  };
  details: {
    bet: number;
    battle_id: number;
    end: string;
    win: number;
  };
}

// const mock: DataType[] = [
//   {
//     id: 1,
//     battle: mockImg,
//     bets: { bet: 100, lose: 120 },
//     details: { bet: 19, battle_id: 10, end: "11.11.11", win: 100 }
//   }
// ];

const BattleHistory = () => {


  const columns: TableProps<DataType>['columns'] = [
    {
      sortIcon: ({ sortOrder }) => <Swap className={styled.swap} order={sortOrder} />,
      dataIndex: 'id',
      key: 'id',
      render: (text) => <p>{text}</p>,
      sorter: (a, b) => b?.id - a?.id,
      width: 50
    },
    {
      title: 'Battle',
      dataIndex: 'battle',
      key: 'battle',
      render: (battle) => <img src={battle} alt="cover" />
    },
    {
      title: 'Bets',
      dataIndex: 'bets',
      key: 'bets',
      render: (bets) => <div>
        <p>Bet: {bets.bet}</p>
        <p>Lose: {bets.lose}</p>
      </div>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (details) => <div>
        <p>{details.bet}</p>
        <p>Battle #{details.battle_id}</p>
        <p>End: {details.end_date}</p>
        <p>Win: {details.win}</p>
      </div>
    },
  ];



  return (
    <ProfileLayout>
      <Table className={styled.table} columns={columns}/>
    </ProfileLayout>
  )
}

export default BattleHistory;
