import React from "react";

const Song = () => {
 return(
  <>
  Song
  </>
 )
}

export default Song;
