import React from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './bonus.module.scss'
import { Button, Input } from "antd";

const Bonus = () => {
 return (
  <ProfileLayout>
   <div className={styles.buy_tokens_header}>

    <h2 className={styles.buy_tokens_header__text}>BONUS - FREE TOKENS</h2>
   </div>

  <div className={styles.bonus_filling}>
   <p className={styles.bonus_filling_header}>How to get free tokens?</p>
   <p>1.  Subscribe to our Instagram account: @Music_bet</p>
   <p>2. Send us a message on Instagram with the text: “MONETIZE MY SUBSCRIBERS”</p>
   <p>3. Copy the Promo code into this field that you will receive in a reply message on Instagram</p>
   <p>4. You will receive free tokens to your Music.bet wallet balance. </p>
   <h4 style={{marginBottom: 10}}>Token exchange rate: 1000 subscribers = 1 tkn</h4>
  </div>
  <Input placeholder="Enter Promo code from message"/>
  <Button className={styles.button}>Get free tokens</Button>
  </ProfileLayout>
 )
}

export default Bonus;
