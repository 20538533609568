import { ProfileLayout } from '../ProfileLayout/ProfileLayout';




const ProfileSettings = () => {

  return (
    <>
      <ProfileLayout>


      </ProfileLayout>
    </>
  );
};

export { ProfileSettings };
