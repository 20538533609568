import { ProfileLayout } from '../ProfileLayout/ProfileLayout';
import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ProfileWallet.module.scss';

import peggy from '../../assets/mock/Peggy-Gou-LP-web-e1712588331306.jpg'
import illit from '../../assets/mock/battle.jpg'
import MyMusic from '../MyMusic';

const ProfileWallet = () => {


  const { firstName, lastName, nickname, transactions, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);

  const mock = [
    { id: 1, genre: "Pop", artist: "ILLIT (아일릿)", track: "Magnetic", year: 2024, price: 6000, win: 100, income: 12000, image: illit},
    { id: 1, genre: "Pop", artist: "Peggy Gou", track: "Seoulsi Peggygou (서울시페기구)", year: 2024, price: 6000, win: 100, income: 12000, image: peggy }

  ]

  return (
    <ProfileLayout>
      <div className={s.buy_tokens_header}>
        <h2 className={s.buy_tokens_header__text}>My Music</h2>
      </div>
    {mock.map((item) => {
    return(
      <MyMusic
      image={item.image}
      genre={item.genre}
      artist={item.artist}
      track={item.track}
      year={item.year}
      price={item.price}
      win={item.win}
      income={item.income}
      />
    )
    })}

    </ProfileLayout>
  )
};

export default ProfileWallet;
