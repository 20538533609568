import React, { useState } from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './Marketplace.module.scss'
import { Button, Divider, Input, List } from "antd";
import { ReactComponent as Search } from '../../assets/musicbet/video_search.svg'
import { ReactComponent as Settings } from '../../assets/musicbet/tune.svg'
import { ReactComponent as Arrow } from '../../assets/musicbet/arrow_drop_down.svg'


import peggy from '../../assets/mock/Peggy-Gou-LP-web-e1712588331306.jpg'
import illit from '../../assets/mock/battle.jpg'
import MarketplaceSong from "../../components/MarketplaceSong";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as Sort } from '../../assets/musicbet/swap_vert.svg'
import { useLocation, useSearchParams } from "react-router-dom";

const MarketPlace = () => {
  const [searchParams] = useSearchParams();
  const [isFiltersShown, setFiltersShown] = useState(false)
  const [isGenreShown, setGenreShown] = useState(false)
  const [isYearShown, setYearShown] = useState(false)
  const [isSortShown, setSortShown] = useState(false);


  let songId: any = searchParams.get('id');
  console.log(songId)
  const [filters, setFilters] = useState({ year: null, genre: null, sort: null });

  console.log(filters)

  const setYearFilter = (year) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      year: year
    }));
  };

  const setGenreFilter = (genre) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      genre: genre
    }));
  };

  // const setSortFilter = (sort) => {
  //  setFilters((prevFilters) => ({
  //   ...prevFilters,
  //   sort: sort
  //  }));
  // };

  const showGenre = () => {
    if (isGenreShown === true) {
      setGenreShown(false)
    } else {
      setYearShown(false)
      setGenreShown(true)
    }
  }

  const showYear = () => {
    if (isYearShown === true) {
      setYearShown(false)

    } else {
      setYearShown(true)
      setGenreShown(false)
    }
  }

  const showFilters = () => {
    if (isFiltersShown === true) {
      setFiltersShown(false)
    } else {
      setFiltersShown(true)
      setYearShown(false)
      setSortShown(false)
    }
  }

  const showSort = () => {
    if (isSortShown === true) {
      setSortShown(false)
    } else {
      setSortShown(true)
      setFiltersShown(false)
      setGenreShown(false)
      setYearShown(false)
    }
  }
  const years = [
    2020, 2021, 2022, 2023, 2024
  ]

  const genre = [
    "Pop",
    'Latin',
    "Rock",
    "Hip-Hop",
    'R&B',
    'Country',
    'EDM',
    'Jazz',
    'Classic'
  ]

  const genres = genre.map(g => ({
    value: g,
    label: g
  }));


  // const newYears = years.map(y => ({
  //  value: y,
  //  label: y
  // }))

  const mock = [
    { id: 1, genre: "Pop", artist: "ILLIT (아일릿)", track: "Magnetic", year: 2024, price: 6000, win: 100, income: 12000, image: illit },
    { id: 1, genre: "Pop", artist: "Peggy Gou", track: "Seoulsi Peggygou (서울시페기구)", year: 2024, price: 6000, win: 100, income: 12000, image: peggy }

  ]

  if (!songId) {
    return (
      <>
        <MusicHeader title="Marketplace" primaryColor="#F12027" color="#fff" />

        <div className={styles.wrapper}>
          <div className={styles.filters}>
            <Input
              placeholder="Search"
              suffix={
                <Button className={styles.button_search} type="link">
                  <Search />
                </Button>
              }
            />
            <div className={styles.settings}>
              <Button onClick={() => { showFilters() }} className={styles.filters_btn}>
                <Settings />
                <p>Filters</p>
              </Button>
              <Button onClick={() => { showSort() }} className={styles.filters_btn}>
                <Sort />
                <p>Sort</p>
              </Button>

            </div>

            <div className={styles.filters_all} style={isFiltersShown ? { display: "flex" } : { display: 'none' }}>
              <div className={styles.filters_column}>

                <Button onClick={() => showGenre()} className={styles.filters_btn}>
                  <Arrow />
                  <p>Genre</p>
                </Button>
                <Button onClick={() => showYear()} className={styles.filters_btn}>
                  <Arrow />
                  <p>Year</p>
                </Button>
              </div>

              <div className={styles.all_genres} style={isGenreShown ? { display: "grid" } : { display: 'none' }}>
                {genres.map((item) => {
                  return (
                    <Button className={styles.filter_button} onClick={() => { setFiltersShown(false); setGenreFilter(item.value) }}>{item.value}</Button>
                  )
                })}
              </div>
              <div className={styles.all_genres} style={isYearShown ? { display: "grid" } : { display: 'none' }}>
                {years.map((item) => {
                  return (
                    <Button className={styles.filter_button} onClick={() => { setFiltersShown(false); setYearFilter(item) }}>{item}</Button>
                  )
                })}
              </div>
              <div className={styles.all_genres} style={isSortShown ? { display: "grid" } : { display: 'none' }}>
                {years.map((item) => {
                  return (
                    <Button className={styles.filter_button} onClick={() => { setFiltersShown(false); setYearFilter(item) }}>{item}</Button>
                  )
                })}
              </div>
            </div>

            <div style={isSortShown ? { display: "flex" } : { display: "none" }} className={styles.sort_by}>
              <Button className={styles.button}>Price: Low to High</Button>
              <Button className={styles.button}>Price: High to Low</Button>

            </div>
            <Divider style={filters.genre != null || filters.year != null ? { background: "#fff" } : { display: 'none' }} />
            <div className={styles.filter_buttons}>
              <div>
                {filters.genre != null ? <Button className={styles.all_filters_btns} onClick={() => setGenreFilter(null)}>{filters.genre} <CloseOutlined /></Button> : <></>}
                {filters.year != null ? <Button className={styles.all_filters_btns} onClick={() => setYearFilter(null)}>{filters.year} <CloseOutlined /></Button> : <></>}
              </div>
              {filters.year || filters.genre ? <Button onClick={() => { setGenreFilter(null); setYearFilter(null) }} className={styles.all_filters_btns}>Clear</Button> : <></>}
            </div>
            <div className={styles.songs}>

              <List
                pagination={
                  { position: 'bottom', align: 'center' }
                }

                dataSource={mock}
                renderItem={(item, index) => (
                  <MarketplaceSong
                    id={item.id}
                    genre={item.genre}
                    cover={item.image}
                    artist={item.artist}
                    track={item.track}
                    year={item.year}
                    price={item.price}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
  else {
    return (
      <div className={styles.checkout_wrapp}>
        <MusicHeader title="checkout" color="#fff" primaryColor="#F12027" />
        <img className={styles.checkout_cover} src={illit} alt="cover" />
        <div className={styles.checkout_info}>
          <p>Genre: Pop</p>
          <p>Artist: The Weeknd</p>
          <p>Track: Blinding Lights</p>
          <p>Year: 2019</p>
        </div>
        <div>
          <div className={styles.checkout_actions_header}>
            <h2>buy this track</h2>
          </div>
          <div className={styles.checkout_actions_price}>
            <p> 6000 USDT</p>
          </div>
        </div>
        <Button className={styles.checkout_actions_buy}>Buy</Button>
      </div>
    )
  }
}

export default MarketPlace;
