import React from "react";

import { Col, Row } from "antd";

import styled from './TournamentGenres.module.scss'

const TournamentGenres = () => {
 return(
  <Row>
   <Col >
   </Col>
  </Row>
 )
}

export default TournamentGenres;
