/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './MusicTournament.module.scss'

import { Col, Row } from "antd";
import BattleItem from "../../components/BattleItem";
import BattleDivider from "../../components/BattleDivider";
import MusicBattleItem from "../../components/BattleItem";

const MusicTournament = () => {

 const mock = [
  {id: 1, battle: "1", primary_color: ''},
  {id: 2, battle: "2"},
  {id: 3, battle: "3"},
  {id: 4, battle: "4"},
  {id: 5, battle: "5"},
  {id: 6, battle: "6"},

 ]

 return(
  <div>
   <MusicHeader title="Pop Tournament" returnable primaryColor="#FF75E6" color="#fff"/>
  <div className={styles.wrapper}>
   <Row justify={'center'}>
   <Col span={11}><MusicBattleItem id={1}/></Col>
   <Col span={11}><MusicBattleItem id={1}/></Col>
   </Row>
  <Row justify={'center'}>
   <Col span={11}><MusicBattleItem id={1}/></Col>
  </Row>
  <Row justify={'center'}>
   <BattleDivider/>
  </Row>
  <Row justify={'center'}>
  <Col span={11}><MusicBattleItem id={1}/></Col>

  </Row>
  <Row justify={'center'}>
  <Col span={11}><MusicBattleItem id={1}/></Col>
  <Col span={11}><MusicBattleItem id={1}/></Col>

  </Row>
  </div>

  </div>
 )
}

export default MusicTournament;
