import React from "react";

import styled from './MusicHeader.module.scss'
import {ReactComponent as Returnable} from '../../assets/musicbet/returnable.svg'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
 title: string,
 primaryColor: string
 color?: string,
 fontSize?: number
 returnable?: boolean
}

const MusicHeader = (props: HeaderProps) => {
 const navigate = useNavigate()
 return(
  <div style={{background: `linear-gradient(90deg, #000,${props.primaryColor},#000`}} className={styled.music_header}>
   <p className={styled.title} style={{color: props.color, fontSize: props.fontSize}}>{props.title}</p>
   {
    props.returnable ? <Button onClick={() => navigate(-1)} type="link" className={styled.return}><Returnable/></Button> : <></>
   }

  </div>
 )
}

export default MusicHeader;
