
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';

import { LoginModal } from '../Login/LoginModal/LoginModal';

import styled from './HowToPlayAndWin.module.scss';
import { useNavigate } from 'react-router-dom';
import { setIsAge } from '../../store/playerSlice';

const HowToPlayAndWin = () => {
    const dispatch = useAppDispatch();
    const [isAuth, setIsAuth] = useState(false);

    const navigate = useNavigate();
    const isOpenModalLogin = useAppSelector((state) => state.modalLogin.isOpen);
    useEffect(() => {
        if (localStorage.getItem('isAuth')) setIsAuth(true);
        if (!localStorage.getItem('isAuth')) setIsAuth(false);
        if (localStorage.getItem("ageConfirm")) {

            dispatch(setIsAge(true))
        }
        if (!localStorage.getItem("ageConfirm")) {
            dispatch(setIsAge(false))
        }
    }, [dispatch, isAuth, navigate]);

    useEffect(() => {
        if (isOpenModalLogin) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isOpenModalLogin]);

    return (
        <div className={styled.containerWrapper}>
            <LoginModal />
        </div>
    );
};

export { HowToPlayAndWin };
