import { useState } from 'react';
import { Row, Col} from "antd";
import { useRequest } from 'ahooks';

import { musicAPI } from '../../../api/api';
import { HeaderGeneral } from '../../../components/HeaderGeneral/HeaderGeneral';
import { Stages } from '../../../components/Stages';

import style from './newButtons.module.scss'
import styled from './ButtonsPage.module.scss';

import mockTournaments from "./tournament.mock";


// const Button: React.FC<IProps> = ({ title, id, handleClick, cover }) => {
//   return (
//     <button onClick={() => handleClick(id)} className={styled.btnSmall}>
//       <img
//         src={cover}
//         alt=""
//         style={{ objectFit: "cover", width: "-webkit-fill-available", height: "-webkit-fill-available", borderRadius: 9 }}
//       />
//       <div className={styled.btnTitle}>{title}</div>
//     </button>
//   );
// };



const tournamentMerge = (source = [], target = []) => {
  const current = source.slice();

  return target.slice().map((item) => {
    const internalIndex = current.findIndex(({ stage }) => item.stage === stage);

    if (internalIndex !== -1) {
      const internal = Object.assign({}, current[internalIndex]);

      current[internalIndex] = { stage: null };

      return internal;
    }

    return item;
  });
}



const ButtonsPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeBtn, setActiveBtn] = useState(0);
  // const [categories, setCategories] = useState<any[]>([]);

  const { data, loading } = useRequest(musicAPI.getTournaments);

  // useEffect(() => {
  //   musicAPI.getCategories().then(categoriesResponse => {
  //     setCategories(categoriesResponse);
  //     dispatch(loadMusicCategories(categoriesResponse));
  //   });
  // }, [dispatch]);

  const tournaments = tournamentMerge(data, mockTournaments);

  return (
    <>
      <HeaderGeneral title='Tournament / 1st Season' />

      <div className={style.container}>
        <div className={style.btnsWrapper}>
          {/* {tops.map((el, index) => {
            return (
              <Button
              type='link'
              onClick={() => toggleTop(index)}
              key={el.title}
              className={activeBtn === index ? style.activeBtn : style.notActiveBtn}
              >
              <HeaderTournament style={ activeBtn === index ? {color: "#C8102E"} : {}} title={el.title} />
              </Button>
            );
          })}
          </div>

          {
            activeBtn === 0 && (
            <> */}

          <div className={styled.container}>
            <Row>
              <Col span={24} md={20} lg={18} xl={16} xxl={14}>
                <Stages items={tournaments} isLoading={loading} />
              </Col>
            </Row>
          </div>
          {/* </>
          )
        } */}
          {/* {
          activeBtn === 1 && (
            <BattleRandom/>
          )
        } */}

        </div>
      </div>
    </>
  );
};

export { ButtonsPage };
