
import { Button, Input} from "antd";


import { ProfileLayout } from "../ProfileLayout/ProfileLayout";

import { ReactComponent as TRC20 } from '../../assets/musicbet/account/trc20.svg'
import { ReactComponent as Moonpay } from '../../assets/musicbet/account/Moonpay.svg'
import { ReactComponent as Payments } from '../../assets/musicbet/account/pasyment.svg'

import s from './BuyTokens.module.scss'

const BuyTokens = () => {
	return (
		<>
			<ProfileLayout>
				<div>
					<div>
						<div className={s.buy_tokens_header}>

							<h2 className={s.buy_tokens_header__text}>Buy Tokens</h2>
						</div>
						<div style={{ padding: 10 }}>
							<div className={s.buy_tokens_inner_header}>

								<h2>Deposit USDT (TRC20)</h2>
								<TRC20 className={s.trc20} />
							</div>
							<p className={s.step}>1) Buy USDT on Coinbase, Binance or another exchange.</p>
							<p className={s.step}>2) Send/withdraw USDT to the address below
								and select TRC20 as the network.</p>

						</div>
						<Input className={s.input_code} disabled placeholder="0xD91bdb110353F47Cff847829E107224C82f68b7D" />
						<Button className={s.copy_code}>Copy</Button>

					</div>
				</div>


			</ProfileLayout >

			{/* <Modal
					open={isOpenModal && !!iframeSrc}
					title=""
					onCancel={handleCloseModal}
					footer={false}
					maskClosable={false}
					centered
					styles={{ content: { padding: 0 } }}
				>
					<iframe
						title="Title"
						src={iframeSrc}
						style={{
							borderRadius: 7,
							width: "100%",
							height: "95vh",
							border: "none",
						}}
					>
					</iframe>
				</Modal> */}
		</>
	)
}

export default BuyTokens;

// const getSignature = (transAmount, currency, transRefNum) => {
// 	const merchantNumber = process.env.REACT_APP_MID_MERCHANT_ID
// 	const PersonalHashKey = process.env.REACT_APP_HASH_KEY
// 	const notificationUrl = process.env.REACT_APP_BE_BASE_URL + '/' + process.env.REACT_APP_NOTIFICATION_URL

// 	const transComment = ""
// 	const payFor = "FANTASY+BATTLES"
// 	const dispLng = "en-US"
// 	const Brand = ""
// 	const urlRedirect = ""
// 	const transType = "0"
// 	const dispPaymentType = ""
// 	const dispRecurring = "0"
// 	const dispMobile = "auto"
// 	const transInstallments = "1"
// 	const uiVersion = "6"

// 	const signature = sha256(merchantNumber + urlRedirect + notificationUrl + transComment + transRefNum + Brand + transInstallments
// 		+ uiVersion + transType + transAmount + currency + dispPaymentType + payFor + dispRecurring + dispLng + dispMobile + PersonalHashKey)

// 	const Base64Siganture = signature.toString(Base64);

// 	const hash = encodeURIComponent(Base64Siganture);

// 	const src = "https://uiservices.aureavia.com/hosted/?merchantID=" + merchantNumber + "&url_redirect=" + urlRedirect + "&notification_url="
// 		+ notificationUrl + "&trans_comment=" + transComment + "&trans_refNum=" + encodeURIComponent(transRefNum) + "&Brand=" + Brand + "&trans_installments=" + transInstallments
// 		+ "&ui_version=" + uiVersion + "&trans_type=" + transType + "&trans_amount=" + transAmount + "&trans_currency=" + currency + "&disp_paymentType="
// 		+ dispPaymentType + "&disp_payFor=" + encodeURIComponent(payFor) + "&disp_recurring=" + dispRecurring + "&disp_lng=" + dispLng + "&disp_mobile="
// 		+ dispMobile + "&signature=" + hash

// 	return src
// }
