import React from "react";
import styles from './MarketplaceSong.module.scss'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

interface SongProps {
 cover: string,
 id: number,
 genre: string,
 artist: string,
 track: string,
 year: number,
 price: number
}

const MarketplaceSong = (props: SongProps) => {

 const navigate = useNavigate()

 return (
  <div className={styles.wrapper}>
   <div className={styles.wrapper_info}>

    <div className={styles.cover}>
     <img className={styles.cover_image} src={props.cover} alt="cover" />
    </div>
    <div className={styles.info_block}>
     <p>Genre: {props.genre}</p>
     <p>Artist: {props.artist}</p>
     <p>Track: {props.track}</p>
     <p>Year: {props.year}</p>
     <p>Price: {props.price}</p>
    </div>
   </div>
   <Button className={styles.buy} onClick={() => navigate(`?id=${props.id}`)}>
   Buy
   </Button>
  </div>
 )
}

export default MarketplaceSong;
