import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

import styled from './Report.module.scss';
import { contactAPI } from '../../api/api';
import { toast } from 'react-toastify';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import MusicHeader from '../../components/MusicHeader';

const Report = () => {

  return (
    <div className={styled.wrapper}>
      <MusicHeader title='Cookie policy' color='#fff' primaryColor='#0E664B'/>

      <div className={styled.wrapper_text}>
        <p className={styled.text}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus distinctio deleniti maxime in nobis harum neque animi odio rem quaerat voluptatibus quia adipisci et facere, ipsa, cum unde? Fugit, ipsam.
        Modi laboriosam nam magni ipsam dolorum ratione repellendus aliquid, perferendis minus tempore? Quis eveniet quasi, eos quisquam qui omnis quibusdam veritatis animi est obcaecati exercitationem rem voluptate doloremque modi illo.
        Voluptatum reiciendis voluptate consectetur fuga, est dolorum minus adipisci perferendis iure labore voluptates natus debitis similique eaque tempora, molestiae pariatur! Possimus suscipit maxime obcaecati dolorum inventore voluptates nisi, at dolorem!
        Pariatur explicabo sint asperiores, error voluptate possimus cumque nesciunt, ipsa ullam ea, incidunt repellendus fugiat praesentium deleniti quidem. Animi repellat provident soluta ipsum ipsa quia eum labore, culpa voluptates veritatis?
        Nostrum corrupti, assumenda error illo, et vero minus eaque cum architecto placeat quibusdam numquam ut voluptatibus repellendus rem! Adipisci vitae ratione illum cum asperiores aspernatur explicabo amet aliquam nihil reprehenderit.
        Excepturi illo omnis id minima! Reiciendis, sit repellat! Iste nemo repellat recusandae harum omnis dolor doloribus at dolorum nihil? Illo optio inventore voluptatibus eos. Officia enim aperiam debitis eum non.
        Ut velit, et explicabo ea, iste quos ipsam, recusandae est doloribus aperiam doloremque odit odio commodi eius laborum illum deserunt alias nisi? Enim obcaecati aliquam doloribus illum, veniam sed unde.
        Voluptas perferendis minus alias cumque odio fuga itaque mollitia excepturi laborum ab praesentium, autem, eaque dignissimos odit obcaecati, rerum debitis ullam laboriosam eligendi nihil veniam blanditiis laudantium tenetur est. Debitis.
        Incidunt ipsum at magnam fuga labore voluptatum ducimus quaerat tenetur quo ex maxime suscipit ea veritatis, autem aliquam delectus repellat beatae! Quia dignissimos ullam amet assumenda natus laboriosam neque quis!
        Pariatur quae, quas recusandae modi eligendi tempore aliquam expedita! Earum, quo itaque ducimus eveniet laboriosam recusandae dolorem iusto dignissimos necessitatibus. Eaque in facilis est itaque ipsam neque dicta praesentium consequatur.</p>
      </div>
    </div>
  );
};

export default Report;
