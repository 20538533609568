import React from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './Withdraw.module.scss'
import { Button, Input } from "antd";

const Withdraw = () => {
 return(
<ProfileLayout>
<div className={styles.buy_tokens_header}>

<h2 className={styles.buy_tokens_header__text}>WITHDRAW</h2>
</div>

<div className={styles.input_items}>
<Input placeholder="Enter your crypto wallet address" className={styles.input}/>
<Input type="number" step={0.01} placeholder="0.00 TKN" className={styles.input}/>
<Button className={styles.withdraw}>Withdraw</Button>
</div>
</ProfileLayout>
 )
}

export default Withdraw;